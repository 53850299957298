import type { AuthError } from 'firebase/auth'

const errorMap: Record<string, string | undefined> = {
  'auth/user-not-found': 'User not found',
  'auth/invalid-credential': 'Invalid credential',
  'auth/wrong-password': 'Invalid password',
  'auth/email-already-in-use': 'User already exists!',
  'auth/invalid-custom-token': 'Invalid custom token',
  'auth/invalid-action-code': 'Invalid action code',
}

export const authErrorMessage = ({ code, message }: AuthError) => {
  if (errorMap[code] == undefined) {
    return message
  }
  return errorMap[code]
}

import type { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'

import { useState, useEffect } from 'react'

import { Timestamp } from 'firebase/firestore'
import FirestoreTime from '../types/time'

import type { App } from '../types/app'

import Dashboard from '../components/dashboard'
import Logo from '../components/logo'
import FullPageSpinner from '../components/full-page-spinner'
import Spinner from '../components/spinner'

import {
  addDays,
  intervalToDuration,
  formatDuration,
  format as formatDate,
} from 'date-fns'

import type {
  StudentOrganizationPrompt,
  StudentOrganizationPromptStatus,
} from '../types/studentOrganizationPrompt'

import {
  ResponseDays,
  EasiestCoursesOrgname,
  isExpired,
  imageMetaUrl,
} from '../lib/utils'

import { createAssessmentLink } from '../lib/assessments'

import {
  query,
  collection,
  collectionGroup,
  where,
  orderBy,
  onSnapshot,
  Firestore,
  OrderByDirection,
} from 'firebase/firestore'

import { OrganizationOrder } from '../types/organizationOrder'
import { OrganizationPrompt } from '../types/organizationPrompt'

type OrgAssessmentMap = Record<string, OrganizationPrompt>
type AssessmentOrderMap = Record<string, OrganizationOrder>
type StudentAssessmentMap = Record<string, StudentOrganizationPrompt>

type HomeProps = {
  app?: App
}
const SingingCourseLink =
  'https://www.easiestcourses.com/products/life-changing-singing-course'

const dateFmt = 'M/dd/yyyy'

const disableReason = (disableMeta: ProductDisableMeta): string => {
  switch (disableMeta.type) {
    case 'expired': {
      return `This assessment expired ${formatDate(disableMeta.expired, dateFmt)}.`
    }
    case 'refunded': {
      return 'This assessment has been refunded.'
    }
    case 'not-purchased': {
      return "This assessment hasn’t been purchased."
    }
    case 'mobile': {
      return 'The assessment is currently only available on desktop computers.'
    }
    case 'none': {
      return ''
    }
  }
}

type ShouldDisableProductOpts = {
  order?: OrganizationOrder
  studentAssessment?: StudentOrganizationPrompt
  isMobile: boolean
  isAdmin: boolean
}

type ProductDisableMeta = ProductDisableMetaNone | ProductDisableMetaExpired | ProductDisableMetaRefunded |
  ProductDisableMetaNotPurchased | ProductDisableMetaMobile

type ProductDisableMetaNone = { type: 'none' }
type ProductDisableMetaExpired = { type: 'expired', expired: Date }
type ProductDisableMetaRefunded = { type: 'refunded' }
type ProductDisableMetaNotPurchased = { type: 'not-purchased' }
type ProductDisableMetaMobile = { type: 'mobile' }

const shouldDisableProduct = ({ order, studentAssessment, isMobile, isAdmin }: ShouldDisableProductOpts): ProductDisableMeta => {
  if (isAdmin) {
    return { type: 'none' }
  }

  // If assessment has already been submitted or reviewed, we can't disable it
  if (studentAssessment !== undefined) {
    switch (studentAssessment.status) {
      case 'not-started':
      case 'in-progress': {
        break
      }
      case 'submitted':
      case 'reviewed': {
        return { type: 'none' }
      }
    }
  }

  if (order === undefined) {
    return { type: 'not-purchased' }
  }
  if (order.refunded) {
    return { type: 'refunded' }
  }
  if (order.expires && isExpired(order.expires as Timestamp)) {
    return { type: 'expired', expired: (order.expires as Timestamp).toDate() }
  }
  if (isMobile) {
    return { type: 'mobile' }
  }

  return { type: 'none' }
}

const Home: NextPage<HomeProps> = ({ app }) => {
  const [isMobile, setIsMobile] = useState(false)

  const [orgAssessments, setOrgAssessments] = useState<OrganizationPrompt[]>()

  const [orgAssessmentMap, setOrgAssessmentMap] = useState<OrgAssessmentMap>()
  const [studentAssessmentMap, setStudentAssessmentMap] = useState<StudentAssessmentMap>()
  const [assessmentOrderMap, setAssessmentOrderMap] = useState<AssessmentOrderMap>()

  const [assessmentsReady, setAssessmentsReady] = useState<boolean>(false)

  const [studentSubmissions, setStudentSubmissions] =
    useState<
      Partial<
        Record<StudentOrganizationPromptStatus, StudentOrganizationPrompt[]>
      >
    >()

  // When we've loaded org asssesments, student assessments,
  // student orders, and admin status
  useEffect(() => {
    if (
      orgAssessments === undefined ||
        orgAssessmentMap === undefined ||
        studentAssessmentMap === undefined ||
        assessmentOrderMap === undefined ||
        app?.session === undefined
    ) {
      return
    }
    setAssessmentsReady(true)
  }, [orgAssessments, orgAssessmentMap, studentAssessmentMap, assessmentOrderMap, app?.session])

  // Find org assessments
  useEffect(() => {
    if (!app?.db || !app.session) {
      return
    }

    const q = query(
      collectionGroup(app.db, 'organizationPrompts'),
      where('organizationId', '==', app.org.id),
      orderBy('created', 'desc'),
    )

    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        if (snap.empty) {
          setOrgAssessments([])
          return
        }
        const orgAssessments = snap.docs.map(
          (doc) => doc.data() as OrganizationPrompt,
        )
        setOrgAssessments(orgAssessments)
      },
      (err) => {
        console.log(
          'Error fetching organization prompts',
          err.name,
          err.code,
          err.message,
        )
      },
    )

    return unsubscribe
  }, [app?.db, app?.session])

  // Create org assessment map
  useEffect(() => {
    if (orgAssessments === undefined) {
      return
    }

    if (orgAssessments.length === 0) {
      setOrgAssessmentMap({})
      return
    }

    const orgAssessmentMap = orgAssessments.reduce((map, orgAssessment) => {
      return {
        [orgAssessment.id]: orgAssessment,
        ...map
      }
    }, {})
    setOrgAssessmentMap(orgAssessmentMap)
  }, [orgAssessments])

  // Find student org assessments
  useEffect(() => {
    if (!app?.db || !app.session) {
      return
    }

    const q = query(
      collection(app.db, 'usersAuthd', app.session.uid,
        'studentOrganizationPrompts',
      ),
      where('organizationId', '==', app.org.id)
    )

    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        if (snap.empty) {
          setStudentAssessmentMap({})
          return
        }
        const studentAssessmentMap = snap.docs.reduce((map, doc) => {
          const studentAssessment = doc.data() as StudentOrganizationPrompt
          return {
            [studentAssessment.organizationPromptId]: studentAssessment,
            ...map
          }
        }, {})
        setStudentAssessmentMap(studentAssessmentMap)
      },
      (err) => {
        console.log(
          'Error fetching student organization prompts',
          err.name,
          err.code,
          err.message,
        )
      },
    )

    return unsubscribe
  }, [app?.db, app?.session])

  // Find student org orders
  useEffect(() => {
    if (!app?.db || !app.session) {
      return
    }

    const { db, session: { email } } = app

    const q = query(
      collection(db,
        'organizationsAuthd', app.org.id,
        'organizationOrders',
      ),
      where('email', '==', email)
    )

    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        if (snap.empty) {
          setAssessmentOrderMap({})
          return
        }
        const assessmentOrderMap = snap.docs.reduce((map, doc) => {
          const order = doc.data() as OrganizationOrder
          const assessment = order.products.find(product =>
            product.productType === 'assessment'
          )
          if (assessment === undefined || assessment.productType !== 'assessment') {
            return map
          }
          const { organizationPromptId } = assessment
          return {
            [organizationPromptId]: order,
            ...map
          }
        }, {})
        setAssessmentOrderMap(assessmentOrderMap)
      },
      (err) => {
        console.log(
          'Error fetching organization orders',
          err.name,
          err.code,
          err.message,
        )
      },
    )

    return unsubscribe
  }, [app?.db, app?.session])

  // Watch submissions for admin
  const watchSubmissions = (
    db: Firestore,
    organizationId: string,
    status: StudentOrganizationPromptStatus,
    dir: OrderByDirection,
  ) => {
    const q = query(
      collectionGroup(db, 'studentOrganizationPrompts'),
      where('organizationId', '==', organizationId),
      where('status', '==', status),
      orderBy('submitted', dir),
    )

    const unsubscribe = onSnapshot(
      q,
      (snap) => {
        if (snap.empty) {
          setStudentSubmissions((prev) => {
            // Destructure previous object, omitting status field
            const {[status]: _, ...rest} = prev || {};
            return rest
          })
          return
        }
        const prompts = snap.docs.map(
          (doc) => doc.data() as StudentOrganizationPrompt,
        )

        setStudentSubmissions((prev) => {
          return {
            ...(prev === undefined ? {} : prev),
            [status]: prompts,
          }
        })
      },
      (err) => {
        console.log(
          'Error fetching student organization prompts',
          err.name,
          err.code,
          err.message,
        )
      },
    )

    return unsubscribe
  }

  // Find "Waiting for Review" for admin
  useEffect(() => {
    if (!app?.session?.admin) {
      return
    }
    return watchSubmissions(app.db, app.org.id, 'submitted', 'asc')
  }, [app?.session?.admin])

  // Find "Reviewed" for admin
  useEffect(() => {
    if (!app?.session?.admin) {
      return
    }
    return watchSubmissions(app.db, app.org.id, 'reviewed', 'desc')
  }, [app?.session?.admin])

  // Basic mobile / iOS detection for now due to limitations
  // in video syncing. Need to investigate iPad behavior.
  useEffect(() => {
    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setIsMobile(mobile)
  }, [app])

  type ProductOpts = {
    href: string
    title: string
    image?: string
    disable: ProductDisableMeta
  }

  const Product = ({ href, title, image, disable }: ProductOpts) => {
    const width = 320
    const height = 128

    const img = (
      <img
        width={width}
        height={height}
        src={image}
        className={`border rounded-md shadow ${
          disable.type !== 'none' ? 'opacity-50' : 'hover:shadow-lg'
        }`}
        alt={title}
      />
    )

    return <div>
      {disable.type === 'none' ? (
        (<Link
          href={href}
          className="block opacity-100"
          style={{ width: width, height: height }}>
          {img}
        </Link>)
      ) : (
        <>
          {img}
          <p className="mt-3 font-medium text-xs text-gray-500" style={{ width: width }}>
            {disableReason(disable)}
          </p>
        </>
      )}
    </div>;
  }

  const deadline = (submitted: FirestoreTime) => {
    return addDays((submitted as Timestamp).toDate(), ResponseDays)
  }

  const now = new Date()

  const promptsSection = (
    title: string,
    show: 'remaining' | 'submitted',
    prompts: StudentOrganizationPrompt[],
    orgAssessmentMap: OrgAssessmentMap,
  ) => (
    <div className="max-w-md">
      <h2 className="mt-6 mb-4 font-semibold">{title} <span className="text-gray-500">— {prompts.length}</span></h2>
      <div className="space-y-4">
        {prompts.map((prompt) => orgAssessmentMap[prompt.organizationPromptId] ? (
          <div
            className="flex justify-between text-sm"
            key={`${prompt.organizationPromptId}/${prompt.uid}`}
          >
            <div>
              <h3 className="font-semibold">
                <Link
                  href={createAssessmentLink({ route: orgAssessmentMap[prompt.organizationPromptId].route, studentUid: prompt.uid })}
                >
                  {prompt.student ? prompt.student.longName : '—'}
                </Link>
              </h3>
            </div>
            {prompt.submitted && show === 'remaining' ? (
              <div
                className={`${
                  now.getTime() < deadline(prompt.submitted).getTime()
                    ? 'text-gray-500'
                    : 'text-red-600'
                }`}
              >
                {formatDuration(
                  intervalToDuration({
                    start: now,
                    end: deadline(prompt.submitted),
                  }),
                  {
                    format: ['years', 'months', 'weeks', 'days', 'hours'],
                    delimiter: ', ',
                  },
                )}
                {now.getTime() < deadline(prompt.submitted).getTime()
                  ? ' left'
                  : ' overdue'}
              </div>
            ) : prompt.submitted && show === 'submitted' ? (
              <div className="text-gray-500">
                {formatDate(
                  (prompt.submitted as Timestamp).toDate(),
                  dateFmt,
                )}
              </div>
            ) : null}
          </div>
        ) : null)}
      </div>
    </div>
  )

  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      {!app ? <FullPageSpinner /> : (
        <Dashboard app={app} width="full">
          <div className="m-6 sm:m-0">
            <Logo org={app.org} />
            <div className="mb-8 space-y-4">
              <>
                {app.org.orgname === EasiestCoursesOrgname ? (
                  <Product
                    href={SingingCourseLink}
                    image="/images/products/life-changing-singing-course.png"
                    title="Life-Changing Singing Course"
                    disable={{ type: 'none' }}
                  />
                ) : null}

                {assessmentsReady && orgAssessments ? orgAssessments.map(({ id, route, title, banner }) => (
                  <Product
                    key={id}
                    href={createAssessmentLink({ route })}
                    image={imageMetaUrl(banner)}
                    title={title}
                    disable={shouldDisableProduct({
                      order: assessmentOrderMap ? assessmentOrderMap[id] : undefined,
                      studentAssessment: studentAssessmentMap ? studentAssessmentMap[id] : undefined,
                      isMobile,
                      isAdmin: app.session?.admin || false
                    })}
                  />)) : (
                    <div className="space-x-3">
                      <Spinner color="#909090" />
                      <span className="text-sm font-medium text-gray-500">Loading purchases&hellip;</span>
                    </div>
                  )}
              </>
            </div>
            {app.session?.admin ? (
              <>
                {studentSubmissions && studentSubmissions['submitted'] && orgAssessmentMap
                  ? promptsSection(
                      'Waiting for review',
                      'remaining',
                      studentSubmissions['submitted'],
                      orgAssessmentMap,
                    )
                  : null}
                {studentSubmissions && studentSubmissions['reviewed'] && orgAssessmentMap
                  ? promptsSection(
                      'Completed',
                      'submitted',
                      studentSubmissions['reviewed'],
                      orgAssessmentMap,
                    )
                  : null}
              </>
            ) : null}
          </div>
        </Dashboard>
      )}
    </>
  )
}

export default Home

type SpinnerProps = {
  foreground?: boolean
  className?: string
  color?: string
  style?: React.CSSProperties
}

const Spinner = ({ foreground, className, color, style }: SpinnerProps) => (
  <span
    className={`spinner ${foreground && !color ? 'foreground' : ''} ${
      className || ''
    }`}
    style={{
      ...(color ? { borderTopColor: color, borderLeftColor: color } : {}),
      ...(style || {}),
    }}
  ></span>
)

export default Spinner

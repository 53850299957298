import Spinner from './spinner'

type DashboardProps = {
  darkBg?: boolean
}

const FullPageSpinner: React.FC<DashboardProps> = ({ darkBg }) => {
  return <>
    <style jsx global>
      {`
        html,
        body,
        #__next {
          height: 100%;
        }
        body {
          ${darkBg ? 'background: #9e9e9e;' : ''}
        }
      `}
    </style>

    <div className="h-full flex items-center justify-center" id="full-page-spinner-component">
      <Spinner foreground={darkBg} />
    </div>
  </>;
}

export default FullPageSpinner

import { useState, useEffect, MouseEventHandler } from 'react'

import Head from 'next/head'
import Link from 'next/link'

import type { App } from '../types/app'

import { signOut, AuthError } from 'firebase/auth'

import Spinner from './spinner'
import { Route } from '../types/route'

import { redirect } from '../lib/utils'
import { authErrorMessage } from '../lib/authErrors'

type ErrorMessage = {
  message: string
}

type TitleData = string | (string | undefined)[]

type DashboardProps = {
  app: App
  width: 'full' | 'medium' | 'narrow'
  title?: TitleData
  darkBg?: boolean
  fullHeight?: boolean
  isSupport?: boolean
  newWindowLinks?: boolean
  accessory?: React.ReactNode
  children: React.ReactNode
}

type WidthType = DashboardProps['width']

const PublicRoutes = new Set<string>([
  Route.SignIn,
  Route.SignInToken,
  Route.SignUp,
  Route.Invite,
  Route.AuthAction,
  Route.ResetPassword,
  Route.Support,
  Route.Minimal,
])

const PublicPrivateRoutes = new Set<string>([Route.Invite, Route.Support])

const year = new Date().getFullYear()

const Dashboard: React.FC<DashboardProps> = ({
  app,
  width,
  title,
  darkBg,
  fullHeight,
  isSupport,
  newWindowLinks,
  accessory,
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [signOutError, setSignOutError] = useState<ErrorMessage>()

  const [path, setPath] = useState<string>()

  const { auth, status, session } = app

  // Redirect signed-out users if necessary
  useEffect(() => {
    const loadPage = async () => {
      const path = window.location.pathname
      const isPublic = PublicRoutes.has(path)
      const isPublicPrivate = PublicPrivateRoutes.has(path)

      setPath(path)

      switch (status) {
        case 'signedOut': {
          if (!isPublic) {
            await redirect(Route.SignIn)
          }
          break;
        }
        case 'signedIn': {
          if (isPublic && !isPublicPrivate) {
            await redirect(Route.Home)
          }
          break;
        }
      }

      setLoading(false)
    }

    void loadPage()
  }, [status])

  const handleSignOut: MouseEventHandler = async (e) => {
    e.preventDefault()

    setLoading(true)

    try {
      await signOut(auth)
    } catch (error: unknown) {
      const message = authErrorMessage(error as AuthError)
      if (message !== undefined) {
        setSignOutError({ message })
      }
      setLoading(false)
    }
  }

  const createTitle = (titleData: TitleData | undefined): string => {
    let parts = [app.org.name]
    if (typeof titleData === 'string') {
      parts.push(titleData)
    } else if (Array.isArray(titleData)) {
      parts = parts.concat(
        titleData.flatMap(part => {
          return part !== undefined ? part : []
        })
      )
    }
    return parts.join(' / ')
  }

  const widthClassMap: Record<WidthType, string> = {
    full: '',
    medium: 'sm:max-w-4xl sm:mx-auto',
    narrow: 'sm:max-w-sm sm:mx-auto',
  }

  return <>
    <Head>
      <title>{createTitle(title)}</title>
    </Head>

    <style jsx global>
      {`
        html,
        body,
        #__next {
          height: 100%;
        }
        body {
          ${darkBg ? 'background: #9e9e9e;' : ''}
        }
      `}
    </style>

    {loading ? (
      <div className="h-full flex items-center justify-center" id="dashboard-spinner">
        <Spinner foreground={darkBg} />
      </div>
    ) : (
      <>
        <div
          className={`${widthClassMap[width]} sm:m-8 ${fullHeight ? 'h-full flex items-center justify-center' : ''} ${
            darkBg ? 'dark-bg' : ''
          }`}
        >
          {children}

          {session ? (
            <div
              className={`mt-6 px-6 sm:px-0 text-sm ${darkBg ? 'text-white' : ''} space-y-6`}
            >
              <div className="flex justify-between">
                {path === Route.Home ? (
                  <>
                    <p>
                      <a onClick={handleSignOut}>Sign out &rarr;</a>
                    </p>
                    {signOutError ? <p>{signOutError.message}</p> : null}
                  </>
                ) : (
                  <p>
                    <Link href={Route.Home} legacyBehavior>
                      <a>&larr; Home</a>
                    </Link>
                  </p>
                )}
                {accessory ?? null}
              </div>

              <p className="space-x-3">
                <span>{session.email}</span>
                {!isSupport ? (
                  <Link href={Route.Support} legacyBehavior>
                    <a target={newWindowLinks ? '_blank' : '_self'}>
                      Support &rarr;
                    </a>
                  </Link>
                ) : null}
              </p>
            </div>
          ) : null}
          <p
            className={`px-6 sm:px-0 py-6 text-sm ${darkBg ? 'text-white' : ''}`}
          >
            &copy; {year}
          </p>
        </div>
      </>
    )}
  </>;
}

export default Dashboard

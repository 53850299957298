export enum Route {
  Home = '/',
  SignIn = '/signin',
  SignInToken = '/signin-token',
  SignUp = '/signup',
  Invite = '/invite',
  AuthAction = '/auth-action',
  ResetPassword = '/reset-password',
  Support = '/support',
  Minimal = '/minimal',
}

type CreateAssessmentLink = {
  route: string
  studentUid?: string
}

export const createAssessmentLink = ({ route, studentUid }: CreateAssessmentLink): string => {
  const baseUrl = document.location.href
  const url = new URL(`/assessments/${route}${studentUid ? `/students/${studentUid}` : ''}`, baseUrl)
  return url.toString()
}

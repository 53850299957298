import type { OrganizationPublic } from '../types/organization'
import { DefaultLogoDisplayWidth, imageMetaUrl } from '../lib/utils'

type LogoProps = {
  org: OrganizationPublic
}

const Logo = ({ org }: LogoProps) => (
  <div className="mt-4 mb-8 inline-block">
    {org.logo !== undefined ? (
      <img src={imageMetaUrl(org.logo)} alt={org.name} width={org.logo.displayWidth || DefaultLogoDisplayWidth} style={{aspectRatio: `${org.logo.width} / ${org.logo.height}`}} />
    ) : null}
  </div>
)

export default Logo
